<template>
    <div class="dashboard-container">
      <le-card width="100%" style="padding-bottom:20px" >
        <template>
          <div class="container">
            <div class="card-title" style="padding-top: 20px;">账号管理</div>
            <a-row class="select-where">
              <a-col :span="6" style="display: flex; align-items: center">
                <div style="width: 70px">账号：</div>
                <a-input
                  :min="1"
                  v-model="name"
                  placeholder="请输入账号"
                  style="width: 200px"
                ></a-input>
              </a-col>

              <a-col :span="6" style="display: flex; align-items: center">
                <div style="width: 70px">用途：</div>
                <a-select
                  :default-value="0"
                  v-model="is_stars"
                  style="width: 200px"
                >
                  <a-select-option :value="0"> 全部</a-select-option>
                  <a-select-option :value="1"> 人工查询</a-select-option>
                  <a-select-option :value="2"> 爬虫查询</a-select-option>
                </a-select>
              </a-col>

              <a-col :span="6" style="display: flex; align-items: center">
                <div style="width: 70px">状态：</div>
                <a-select
                  :default-value="0"
                  v-model="follow_up"
                  style="width: 200px"
                >
                  <a-select-option :value="0"> 全部</a-select-option>
                  <a-select-option :value="-1"> 正常使用</a-select-option>
                  <a-select-option :value="1"> 异常</a-select-option>
                </a-select>
              </a-col>
              <a-col :span="6" style="display: flex; align-items: center">
                <le-button
                  txt="查询"
                  width="40px"
                  height="25px"
                  fontSize="14px"
                  style="margin-right: 10px"
                  @btnClick="searchData()"
                />
                <le-button
                  txt="重置"
                  width="40px"
                  height="25px"
                  fontSize="14px"
                  background="linear-gradient(-10deg, #FD8A8F, #FA6A9D)"
                  @btnClick="resetGetData()"
                />
              </a-col>
            </a-row>
            <a-row class="select-where">
              <a-col :span="6" style="display: flex; align-items: center">
                <le-button txt="新增" class="le-button" @btnClick="showEdit=true"  />
              </a-col>
            </a-row>
            <div class="table-box">
              <a-table 
                :columns="columns" 
                :data-source="dataSource"
                :loading="loading"
                :pagination="pageOptions"
                @change="handleTableChange"
                size="middle" >
                  <span slot="action" slot-scope="row">
                    <a @click="clickEdit(row)">编辑</a>
                    <a-divider type="vertical"/>
                    <a-popconfirm title="确定删除吗？" ok-text="确定" cancel-text="取消"   @confirm="toDelete(row)" >
                      <a @click="visible = true;rows = row;">删除</a>
                    </a-popconfirm>
                  </span>
              </a-table>
            </div>
          </div>
        </template>
      </le-card>
      <EditModal :visible.sync="showEdit" />
    </div>
</template>

<script>
import columns from './columns'
import {MixinList} from '@/common/mixin';
import EditModal from './components/EditModal.vue'
export default {
  name: "authRole",
  components: {
    EditModal
  },
  mixins:[MixinList],
  data() {
    return {
      columns,
      dataUrl:'v2/adminRole/sel',
      delUrl:'v2/adminRole/del',
      queryParam:{
        
      },
      showEdit:false
    };
  },
  computed: {
  },
  mounted(){

  },
  methods: {

  }
};
</script>

<style lang="less" scoped>
.dashboard-container {
  width: 100%;
}
.container {
  position: relative;

  .num-box {
    font-size: 14px;
    margin-left: 11px;
    line-height: 32px;
  }

  .table-box {
    margin: 40px 30px;
  }

  .card-title {
    display: flex
  }

  .select-where {
    margin: 30px;

    .select-col {
      display: flex;
      align-items: center;

      .select-tit {
        width: 70px
      }
    }
  }
}
</style>
